<template>
  <div id="root">
    <div class="header-area">
      <h1>试验大纲</h1>
    </div>
    <div class="body-area">
      <el-form ref="form" label-position="top" :model="formData">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>基本信息</span>
          </div>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="试验大纲名称" prop="outlineName">
                <el-input
                  v-model="formData.outlineName"
                  :maxlength="maxlength"
                  show-word-limit
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="委托方单位名称" prop="applyUnit">
                <el-select
                  v-model="formData.applyUnit"
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="company in companys"
                    :key="company.id"
                    :label="company.dictionaryValue"
                    :value="company.dictionaryValue"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="委托方单位地址" prop="applyAddress">
                <el-select
                  v-model="formData.applyAddress"
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="address in company_address"
                    :key="address.id"
                    :label="address.dictionaryValue"
                    :value="address.dictionaryValue"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="试验目的" prop="experimentPurpose">
                <el-input
                  v-model="formData.experimentPurpose"
                  type="textarea"
                  :maxlength="maxlength"
                  show-word-limit
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="盖章日期" prop="ratifyDate">
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  v-model="formData.ratifyDate"
                  type="date"
                  placeholder="选择日期"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8" />
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="引用文件（是指辐照试验方法标准，测试标准或器件数据手册、详细规范等"
                prop="quotedDocument"
              >
                <el-select
                  v-model="formData.quotedDocument"
                  filterable
                  placeholder="请选择"
                  style="width:100%"
                >
                  <el-option
                    v-for="file in include_files"
                    :key="file.id"
                    :label="file.dictionaryValue"
                    :value="file.dictionaryValue"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="适用范围">
                <el-input
                  v-model="formData.useRange"
                  type="textarea"
                  :maxlength="maxlength"
                  show-word-limit
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
        <!-- 样品信息 -->
        <SampleForm :handleType="handleType" ref="SampleForm" />
        <!-- 设备信息 -->
        <DeviceForm :handleType="handleType" ref="DeviceForm" />
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span
              >辐照试验条件要求<span style="font-size:14px;font-weight:normal"
                >（注：本条款应提前考虑到是否允许在试验方案确定后，试验进行过程中对剂量率、累积剂量点进行更改。）</span
              ></span
            >
          </div>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="辐射源" prop="radiationSource">
                <el-checkbox-group v-model="formData.radiationSource">
                  <el-checkbox label="钴源" />
                  <el-checkbox label="电子加速器" />
                  <el-checkbox label="X光机" />
                </el-checkbox-group>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="辐射场均匀性要求（≤10%或xx）"
                prop="radiationRequire1"
              >
                <el-input
                  v-model="formData.radiationRequire1"
                  type="textarea"
                  :maxlength="maxlength"
                  show-word-limit
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item
                label="辐射剂量率要求（需注明剂量允许范围）"
                prop="radiationRequire2"
              >
                <el-input
                  v-model="formData.radiationRequire2"
                  :maxlength="maxlength"
                  show-word-limit
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="预定的辐照剂量点设置"
                prop="radiationSetting"
              >
                <el-input
                  style="width:40%;"
                  v-model="formData.radiationSetting"
                  :maxlength="maxlength"
                  placeholder="请输入剂量点"
                />
                <span class="remark">rad(Si)</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="偏置和负载条件要求" prop="radiationRequire3">
                <el-input
                  v-model="formData.radiationRequire3"
                  type="textarea"
                  :maxlength="maxlength"
                  show-word-limit
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="试验终止条件（如多少总剂量即停止试验或失效停止试验等）"
              >
                <el-input
                  v-model="formData.terminationCondition"
                  type="textarea"
                  :maxlength="maxlength"
                  show-word-limit
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item
                label="辐照环境条件要求（需注明要求的温、湿度范围）"
                prop="radiationWenduAndShidu"
              >
                <RangeFormItem
                  label="温度范围"
                  unit="C°"
                  :min="radiaWenduMin"
                  :max="radiaWenduMax"
                  @getVal="getRangeFormItem"
                  attr="radiationRequire4"
                />
                <RangeFormItem
                  label="湿度范围"
                  unit="%RH"
                  :min="radiaShiduMin"
                  :max="radiaShiduMax"
                  @getVal="getRangeFormItem"
                  attr="radiationRequire4h"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="是否有辐射屏蔽要求" prop="radiationRequire5">
                <el-radio-group v-model="formData.radiationRequire5">
                  <el-radio label="是">是</el-radio>
                  <el-radio label="否">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                v-if="formData.radiationRequire5 === '是'"
                label="辐射屏蔽要求内容（例如需要2mm厚的铅砖1块、屏蔽主电路）"
                prop="radiationRequire5Content"
              >
                <el-input
                  v-model="formData.radiationRequire5Content"
                  type="textarea"
                  :maxlength="maxlength"
                  show-word-limit
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>测试条件要求</span>
            <!-- <span style="margin-right:20px">测试条件要求</span>
            <el-radio-group  v-model="isTest">
              <el-radio label="是">是</el-radio>
              <el-radio label="否">否</el-radio>
            </el-radio-group> -->
          </div>
          <div>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item
                  label="测试项目（如测试参数、特性曲线或功能等）"
                  prop="testProject"
                >
                  <el-input
                    v-model="formData.testProject"
                    :maxlength="maxlength"
                    show-word-limit
                    placeholder="请输入"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="测量方法（如在线或离线、原位或移位等要求）"
                  prop="testMethod"
                >
                  <el-checkbox-group v-model="formData.testMethod">
                    <el-checkbox label="在线" />
                    <el-checkbox label="离线" />
                    <el-checkbox label="原位" />
                    <el-checkbox label="位移" />
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item
                  label="测试环境条件要求（需注明要求的温、湿度范围）"
                  prop="testEnvWenduAndShidu"
                >
                  <RangeFormItem
                    label="温度范围"
                    unit="C°"
                    :min="testEnvWenduMin"
                    :max="testEnvWenduMax"
                    @getVal="getRangeFormItem"
                    attr="testCondition1"
                    ref="testCondition1"
                  />
                  <RangeFormItem
                    label="湿度范围"
                    unit="%RH"
                    :min="testEnvShiduMin"
                    :max="testEnvShiduMax"
                    @getVal="getRangeFormItem"
                    attr="testCondition7"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="测试设备精度要求" prop="testCondition3">
                  <el-input
                    v-model="formData.testCondition3"
                    type="textarea"
                    :maxlength="maxlength"
                    show-word-limit
                    placeholder="请输入"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="防静电措施要求" prop="testCondition2">
                  <el-input
                    v-model="formData.testCondition2"
                    type="textarea"
                    :maxlength="maxlength"
                    show-word-limit
                    placeholder="请输入"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="测试时长不超过" prop="testCondition4">
                  <Hhmmss
                    :time="testTime"
                    :minute="testMinute"
                    :second="testSecond"
                    attr="testCondition4"
                    @inputChange="getTimeMinuteSecond"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="允许的辐照-测试时间间隔要求"
                  prop="testCondition5"
                >
                  <el-input
                    v-model="formData.testCondition5"
                    :maxlength="maxlength"
                    placeholder="请输入"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="委托方认为必要的其它测试要求"
                  prop="testCondition6"
                >
                  <el-input
                    v-model="formData.testCondition6"
                    type="textarea"
                    :maxlength="maxlength"
                    show-word-limit
                    placeholder="请输入"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item
                  label="失效判据（本条款适用于委托方需对器件的抗辐射性能做出考核或评估功能、性能时，提供的失效判据。可以采取明确辐照试验前后电参数变化范围或功能是否满足要求等方式）"
                  prop="failureCriterion"
                >
                  <el-input
                    style="width:50%;"
                    v-model="formData.failureCriterion"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 10 }"
                    :maxlength="maxlength"
                    show-word-limit
                    placeholder="请输入备注"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span
              >辐照试验流程要求<span style="font-size:14px;font-weight:normal;"
                >（对选择是的项，需对相应试验过程、条件等做出具体要求。可以直接采用流程图表示。通常依据标准确定，比如器件辐照后测试参数超差则做室温退火试验，每次退火时长多久等）</span
              ></span
            >
          </div>
          <el-row :gutter="20">
            <el-form-item
              label="是否进行辐照后的室温退火试验"
              prop="afterExperiment"
            >
              <el-radio-group v-model="formData.afterExperiment">
                <el-radio label="是">是</el-radio>
                <el-radio label="否">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              v-show="formData.afterExperiment === '是'"
              label="室温退火试验内容"
              prop="afterExperimentContent"
            >
              <el-input
                style="width:50%;"
                v-model="formData.afterExperimentContent"
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 10 }"
                :maxlength="maxlength"
                show-word-limit
                placeholder="请输入室温退火试验内容"
              />
            </el-form-item>
            <el-form-item label="是否进行过辐照试验" prop="radiationExperiment">
              <el-radio-group v-model="formData.radiationExperiment">
                <el-radio label="是">是</el-radio>
                <el-radio label="否">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              v-show="formData.radiationExperiment === '是'"
              label="辐照试验内容"
              prop="radiationExperimentContent"
            >
              <el-input
                style="width:50%;"
                v-model="formData.radiationExperimentContent"
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 10 }"
                :maxlength="maxlength"
                show-word-limit
                placeholder="请输入辐照试验内容"
              />
            </el-form-item>
            <el-form-item label="是否进行加速退火试验" prop="annealExperiment">
              <el-radio-group v-model="formData.annealExperiment">
                <el-radio label="是">是</el-radio>
                <el-radio label="否">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              v-show="formData.annealExperiment === '是'"
              label="加速退火试验内容"
              prop="annealExperimentContent"
            >
              <el-input
                style="width:50%;"
                v-model="formData.annealExperimentContent"
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 10 }"
                :maxlength="maxlength"
                show-word-limit
                placeholder="请输入加速退火试验内容"
              />
            </el-form-item>
          </el-row>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>提交试验结果要求</span>
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="试验数据形式、内容等方面特殊要求（如数据必须经过处理、只要txt格
式、或者需要原始数据刻录光盘等）"
                prop="dataRequire"
              >
                <el-input
                  v-model="formData.dataRequire"
                  type="textarea"
                  :maxlength="maxlength"
                  show-word-limit
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="试验报告形式、内容等方面特殊要求（如报告必须包含的要素、或需要
判定结论、需要剂量率标定报告或仅需要纸质版试验报告等）"
                prop="reportRequire"
              >
                <el-input
                  v-model="formData.reportRequire"
                  type="textarea"
                  :maxlength="maxlength"
                  show-word-limit
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8" />
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="试验结果提交期限要求（如试验全部结束后30个工作日内出具报告等）"
                prop="dateRequire"
              >
                <el-input
                  v-model="formData.dateRequire"
                  type="textarea"
                  :maxlength="maxlength"
                  show-word-limit
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="试验结果共享或保密要求（如谁有权查看或仅限双方知悉等）"
                prop="shareRequire"
              >
                <el-input
                  v-model="formData.shareRequire"
                  type="textarea"
                  :maxlength="maxlength"
                  show-word-limit
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8" />
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="其它试验任务发布者认为必要的试验结果要求"
                prop="otherRequire"
              >
                <el-input
                  v-model="formData.otherRequire"
                  type="textarea"
                  :maxlength="maxlength"
                  show-word-limit
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8" />
            <el-col :span="8" />
          </el-row>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span
              >其它要求（比如明确测试由谁负责、器件是否交接等其他内容）</span
            >
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="测试由谁负责">
                <el-input
                  style="width:200px"
                  v-model="formData.otherTestLeader"
                  :maxlength="maxlength"
                  show-word-limit
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="器件是否交接">
                <el-radio-group v-model="formData.otherJoin">
                  <el-radio label="是">是</el-radio>
                  <el-radio label="否">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="其它">
                <el-input
                  style="width:500px"
                  type="textarea"
                  v-model="formData.otherContent"
                  :autosize="{ minRows: 2, maxRows: 10 }"
                  :maxlength="maxlength"
                  show-word-limit
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </el-form>
      <div class="footer">
        <div class="footer-btn">
          <el-button
            size="medium"
            type="primary"
            @click="print"
            :loading="btnLoading"
            >打印</el-button
          >
          <el-button size="medium" @click="toBack">取消</el-button>
        </div>
      </div>
    </div>
    <!-- 打印大纲 -->
    <Print :printData="formData" :visible.sync="visible" />
  </div>
</template>

<script>
import { outLineDetail } from "@/api/outline";
import SampleForm from "@/views/outline/components/form/sampleForm";
import DeviceForm from "@/views/outline/components/form/deviceForm";
import Hhmmss from "@/components/Hhmmss";
import RangeFormItem from "@/components/RangeFormItem";
import { getDictList } from "@/api/dict";
import { dict } from "@/utils/dic";
import Print from "./print";
export default {
  components: {
    SampleForm,
    DeviceForm,
    Hhmmss,
    RangeFormItem,
    Print
  },
  data() {
    return {
      maxlength: 64,
      handleType: this.$route.query.type || "find",
      radiationRequire: [],
      btnLoading: false,
      /**
       * 是否需要测试
       */
      isTest: false,
      /**
       * 测试环境条件要求（需注明要求的温、湿度范围）
       */
      testEnvWenduMin: "",
      testEnvWenduMax: "",
      testEnvShiduMin: "",
      testEnvShiduMax: "",
      /**
       * 辐照环境条件要求（需注明要求的温、湿度范围）
       */
      radiaWenduMin: "",
      radiaWenduMax: "",
      radiaShiduMin: "",
      radiaShiduMax: "",
      /**
       * 测试时长不超过
       */
      testTime: 0,
      testMinute: 0,
      testSecond: 0,
      /**
       * 委托方单位名称
       * 委托方单位地址
       * 引用文件
       */
      companys: [],
      company_address: [],
      include_files: [],
      visible: false,
      formData: {
        radiationSource: [],
        testMethod: [],
        useRange: ""
      },
      sampleData: [
        {
          Name: "",
          Num: "",
          Amount: "",
          Model: "",
          Batch: "",
          TechniqueInfo: "",
          pinout: "",
          packageDiagram: "",
          qualityLevel: "",
          manufacturer: "",
          function: ""
        }
      ]
    };
  },
  mounted() {
    if (this.$route.params.outlineId) {
      this.outLineDetail();
    }
    this.getDictList();
  },
  methods: {
    // 提交
    print() {
      this.visible = true;
    },
    // 获取详情
    outLineDetail() {
      outLineDetail(this.$route.params.outlineId)
        .then(res => {
          /**
           * 辐射源
           */
          if (res.data.radiationSource) {
            res.data.radiationSource = res.data.radiationSource.split(",");
          } else {
            res.data.radiationSource = [];
          }
          /**
           * 测量方法
           */
          if (res.data.testMethod) {
            res.data.testMethod = res.data.testMethod.split(",");
          } else {
            res.data.testMethod = [];
          }
          /**
           * 测试时长不超过
           */

          if (res.data.testCondition4) {
            let testCondition4 = res.data.testCondition4.split(",");
            this.testTime = parseInt(testCondition4[0]);
            this.testMinute = parseInt(testCondition4[1]);
            this.testSecond = parseInt(testCondition4[2]);
          }
          /**
           * 测试环境条件要求（需注明要求的温、湿度范围）
           */

          if (res.data.testCondition1) {
            let testCondition1 = res.data.testCondition1.split(",");
            this.testEnvWenduMin = testCondition1[0];
            this.testEnvWenduMax = testCondition1[1];
          }
          if (res.data.testCondition7) {
            let testCondition7 = res.data.testCondition7.split(",");
            this.testEnvShiduMin = testCondition7[0];
            this.testEnvShiduMax = testCondition7[1];
          }
          /**
           * 辐照环境条件要求（需注明要求的温、湿度范围）
           */

          if (res.data.radiationRequire4) {
            let radiationRequire4 = res.data.radiationRequire4.split(",");
            this.radiaWenduMin = radiationRequire4[0];
            this.radiaWenduMax = radiationRequire4[1];
          }
          if (res.data.radiationRequire4h) {
            let radiationRequire4h = res.data.radiationRequire4h.split(",");
            this.radiaShiduMin = radiationRequire4h[0];
            this.radiaShiduMax = radiationRequire4h[1];
          }
          /**
           * 样品
           */

          if (res.data.testSampleInfo) {
            this.$refs.SampleForm.tableData = JSON.parse(
              res.data.testSampleInfo
            );
          }
          /**
           * 设备
           */

          let testEquipmentInfo = { lhs: [], entrust: [] };
          if (res.data.testEquipmentInfo) {
            testEquipmentInfo = JSON.parse(res.data.testEquipmentInfo);
          }
          this.$refs.DeviceForm.entrustTableData = testEquipmentInfo.entrust
            ? testEquipmentInfo.entrust
            : [];
          this.$refs.DeviceForm.lhsTableData = testEquipmentInfo.lhs
            ? testEquipmentInfo.lhs
            : [];
          this.formData = res.data;
          this.$set(
            this.formData,
            "useRange",
            "本试验大纲适用于 XX 单位在中科院新疆理化所 60Co-γ 射线源（或电子加速 器、X 光机）上进行的半导体材料/器件/电路辐照试验。"
          );
        })
        .catch(err => {
          console.log(err);
          this.$message.error("获取失败");
        });
    },
    /**
     * 获取时分秒
     */
    getTimeMinuteSecond(attr, val) {
      this.$set(this.formData, attr, val);
    },
    /**
     * 获取温度、湿度范围表单值
     */
    getRangeFormItem(attr, val) {
      this.$set(this.formData, attr, val);
    },
    /**
     * 获取字典数据
     */
    getDictList() {
      getDictList({ size: -1 }).then(res => {
        let companys = [];
        let company_address = [];
        let include_files = [];
        res.data.records.forEach(item => {
          if (item.dictionaryType === dict.APPLY_UNIT.Nub) {
            companys.push(item);
          } else if (item.dictionaryType === dict.APPLY_UNIT_ADDRESS.Nub) {
            company_address.push(item);
          } else if (item.dictionaryType === dict.INCLUDE_FILE.Nub) {
            include_files.push(item);
          }
        });
        this.companys = companys;
        this.company_address = company_address;
        this.include_files = include_files;
      });
    },
    toBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
#root {
  position: relative;
  .box-card {
    margin-bottom: 10px;
    .add-btn {
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 28px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    }
  }
  .remark {
    margin: 0 10px;
    color: #c0c4cc;
    font-size: 13px;
  }
}
</style>

<template>
  <el-dialog
    center
    width="1000px"
    ref="form"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="handleCancel"
  >
    <div id="print" ref="print">
      <h3 class="number">
        编号：
        <span class="under-line">{{ formData.Num }}</span>
      </h3>
      <h2 class="experiment-name">{{ formData.experimentName }}</h2>
      <div class="report-content">
        <h3>
          报告编制：
          <span class="under-line">
            {{ formData.preparePeople }}
          </span>
        </h3>
        <h3>
          报告校对：
          <span class="under-line">
            {{ formData.proofreadPeople }}
          </span>
        </h3>
        <h3>
          报告审核：
          <span class="under-line">
            {{ formData.auditPeople }}
          </span>
        </h3>
        <h3>
          报告批准：
          <span class="under-line">
            {{ formData.approvePeople }}
          </span>
        </h3>
      </div>
      <h4 class="center">
        中国科学院新疆理化技术研究所电子元器件辐射效应实验室（盖章）
      </h4>
      <h4 class="center">{{ formData.ratifyDate | chinesizeTime }}</h4>
      <div style="page-break-after: always;"></div>
      <h4 class="title">一、适用范围</h4>
      <p class="text-indent">
        {{ formData.useRange }}
      </p>
      <h4 class="title">二、试验目的</h4>
      <p class="text-indent">
        {{ formData.experimentPurpose }}
      </p>
      <h4 class="title">三、文件依据</h4>
      <p class="text-indent">
        {{ formData.quotedDocument }}
      </p>
      <h4 class="title">四、 试验样品</h4>
      <table style="width:100%" cellspacing="0" border="1">
        <tr>
          <th valign="middle" align="center" style="width:30px" rowspan="2">
            被试件
          </th>
          <th>编号</th>
          <th>名称</th>
          <th>数量</th>
          <th>批次</th>
        </tr>
        <tr v-for="(sample, index) in formData.testSampleInfo" :key="index">
          <td>{{ sample.Num }}</td>
          <td>{{ sample.Name }}</td>
          <td>{{ sample.Amount }}</td>
          <td>{{ sample.Batch }}</td>
        </tr>
      </table>

      <h4 class="title">五、 试验设备</h4>
      <p class="text-indent">委托方自带设备：</p>
      <table style="width:100%" cellspacing="0" border="1">
        <tr>
          <th>设备名称</th>
          <th>设备型号</th>
          <th>计量有效期</th>
        </tr>
        <tr v-for="(device, index) in entrustDevices" :key="index">
          <td>{{ device.applyEquName }}</td>
          <td>{{ device.applyEquModel }}</td>
          <td>{{ device.applyEquDateRange }}</td>
        </tr>
      </table>

      <p class="text-indent">理化所提供设备：</p>
      <table style="width:100%" cellspacing="0" border="1">
        <tr>
          <th>设备名称</th>
          <th>技术指标、精度要求</th>
        </tr>
        <tr v-for="(device, index) in lhsDevices" :key="index">
          <td>{{ device.pyhsicalEquName }}</td>
          <td>{{ device.pyhsicalEquTarget }}</td>
        </tr>
      </table>

      <h4 class="title">六、辐照试验条件要求</h4>
      <p class="text-indent">
        辐射源包括均匀性要求：{{ formData.radiationRequire1 }}
      </p>
      <p class="text-indent">
        辐照剂量率及允许的误差范围：{{ formData.radiationRequire2 }}
      </p>
      <p class="text-indent">
        预定的辐照剂量点设置：{{ formData.radiationSetting }}
      </p>
      <p class="text-indent">
        试验终止条件：{{ formData.terminationCondition }}
      </p>
      <p class="text-indent">
        辐照时的偏置和负载条件要求：{{ formData.radiationRequire3 }}
      </p>
      <p class="text-indent">
        辐照环境温度要求：{{ formData.radiationRequire4 }}
      </p>
      <!-- <p class="text-indent">
        辐射屏蔽要求：{{formData.radiationRequire5Content}}
      </p> -->
      <h4 class="title">七、测试条件要求</h4>
      <p class="text-indent">
        辐照试验中需测试的参数、特性曲线、功能等：{{ formData.testProject }}
      </p>
      <p class="text-indent">
        参数测量方法：{{ formData.testMethod | arrToStr }}
      </p>
      <p class="text-indent">
        测试条件：温度范围：{{ formData.testCondition1 }}，湿度范围：{{
          formData.testCondition7
        }}
      </p>
      <p class="text-indent">
        试验终止条件：{{ formData.terminationCondition }}
      </p>
      <p class="text-indent">
        测试时长要求及允许的辐照-测试时间间隔要求：{{ formData.testCondition5 }}
      </p>
      <p class="text-indent">
        委托方认为必要的其它测试要求：{{ formData.testCondition6 }}
      </p>
      <h4 class="title">八、失效判据</h4>
      <p class="text-indent">
        {{ formData.failureCriterion }}
      </p>
      <h4 class="title">九、辐照试验流程要求</h4>
      <p class="text-indent">
        是否进行辐照后的室温退火试验：{{ formData.afterExperiment }}
      </p>
      <p class="text-indent">
        室温退火试验内容：{{ formData.afterExperimentContent }}
      </p>
      <p class="text-indent">
        是否进行过辐照试验：{{ formData.radiationExperiment }}
      </p>
      <p class="text-indent">
        辐照试验内容：{{ formData.radiationExperimentContent }}
      </p>
      <p class="text-indent">
        是否进行加速退火试验：{{ formData.annealExperiment }}
      </p>
      <p class="text-indent">
        加速退火试验内容：{{ formData.annealExperimentContent }}
      </p>
      <h4 class="title">十、提交试验结果要求</h4>
      <p class="text-indent">
        试验数据形式、内容等方面特殊要求：{{ formData.dataRequire }}
      </p>
      <p class="text-indent">
        试验报告形式、内容等方面特殊要求：{{ formData.reportRequire }}
      </p>
      <p class="text-indent">
        试验结果提交期限要求：{{ formData.dateRequire }}
      </p>
      <p class="text-indent">
        试验结果共享或保密要求：{{ formData.shareRequire }}
      </p>
      <p class="text-indent">
        其它试验任务发布者认为必要的试验结果要求：{{ formData.otherRequire }}
      </p>
      <h4 class="title">十一、 其它要求</h4>
      <p class="text-indent">测试由谁负责:{{ formData.otherTestLeader }}</p>
      <p class="text-indent">器件是否交接:{{ formData.otherJoin }}</p>
      <p class="text-indent">其它:{{ formData.otherContent }}</p>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="print">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { chinesizeTime } from "@/utils";
export default {
  props: {
    printData: {
      type: Object,
      default: () => {}
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formData: {}
    };
  },
  computed: {
    entrustDevices() {
      if (
        this.formData.testEquipmentInfo &&
        this.formData.testEquipmentInfo.entrust.length > 0
      ) {
        console.log(this.formData.testEquipmentInfo.entrust);

        return this.formData.testEquipmentInfo.entrust;
      }
      return [];
    },
    lhsDevices() {
      if (
        this.formData.testEquipmentInfo &&
        this.formData.testEquipmentInfo.lhs.length > 0
      ) {
        console.log(this.formData.testEquipmentInfo.lhs);

        return this.formData.testEquipmentInfo.lhs;
      }
      return [];
    }
  },
  watch: {
    printData(data) {
      let formData = { ...data };
      formData.testSampleInfo = JSON.parse(formData.testSampleInfo);
      formData.testEquipmentInfo = JSON.parse(formData.testEquipmentInfo);
      this.formData = formData;
      console.log(this.formData);
    }
  },
  methods: {
    print() {
      /**这里延迟处理，解决360浏览器打印弹窗不隐藏问题 */
      setTimeout(() => {
        this.$print(this.$refs.print);
        this.handleCancel();
      }, 300);
    },
    handleCancel() {
      this.$emit("update:visible", false);
    }
  },
  filters: {
    chinesizeTime(time) {
      return chinesizeTime(time);
    },
    arrToStr(arr) {
      if (Array.isArray(arr)) return arr.join(",");
      return "";
    }
  }
};
</script>

<style scoped>
.center {
  text-align: center;
}
.number {
  font-weight: normal;
  text-align: right;
}
.title {
  text-align: left;
}
.text-indent {
  text-indent: 2em;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: center;
}
.under-line {
  display: inline-block;
  min-width: 200px;
  border-bottom: 1px solid #000;
  text-align: left;
}
table,
table tr th,
table tr td {
  border: 1px solid #000;
  padding: 5px;
}
table {
  border-collapse: collapse;
  text-align: left;
  font-size: 14px;
}

@media print {
  h1 {
    color: #000;
    background: none;
  }
  .number {
    margin-bottom: 5cm !important;
  }
  .experiment-name {
    margin-bottom: 6cm !important;
  }
  .report-content {
    margin-bottom: 6cm !important;
  }
  .final-content {
    margin-top: 8cm !important;
  }
  nav,
  aside {
    display: none;
  }

  body,
  article {
    font-size: 13pt;
    font-family: "宋体", "SimSun", "serif", "sans-serif", "cursive", "fantasy",
      "monospace";
    line-height: 1.3;
    width: 100%;
    margin: 0;
    padding: 0;
    color: #000;
    background: #fff;
  }

  @page {
    margin: 2cm;
    size: A4;
  }
}
</style>
